import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import withPreview from '../../utils/with-preview';
import AlertCircle from '../../atoms/static-icons/alert-circle';
import classNames from 'classnames';

function FastQuoteMotorbike({ data }) {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'all',
  });

  const [motorbikePlate, setmotorbikePlate] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [email, setEmail] = useState('');

  const motorbikePlateErrorMessage =
    'Inserisci una targa lunga al massimo 8 cifre';
  const dateOfBirthErrrorMessage = 'Inserisci una data in formato GG/MM/AAAA';
  const emailErrorMessage = 'Inserisci un formato di email valido.';

  const privacyInfo = {
    introText: data?.privacy?.content?.privacyIntroText?.value,
    doc: data?.privacy?.content?.privacyDoc,
    docURL: data?.privacy?.content?.privacyDoc?.value?.contentUrl,
    title: data?.privacy?.value,
    outroText: data?.privacy?.content?.privacyOutroText?.value,
    showRadioPrivacy: data?.privacy?.content?.showRadioPrivacy?.value,
    btnValue: data?.btnLabel?.value,
  };

  const commercialConsentInfo = {
    introText:
      data?.commercialConsent?.content?.commercialConsentIntroText?.value,
    doc: data?.commercialConsent?.content?.commercialConsentDoc,
    docURL:
      data?.commercialConsent?.content?.commercialConsentDoc?.value?.contentUrl,
    title: data?.commercialConsent?.value,
    outroText:
      data?.commercialConsent?.content?.commercialConsentOutroText?.value,
    // showRadioCommercialConsent:
    //   data?.commercialConsent?.content?.showRadioCommercialConsent?.value,
    btnValue: data?.btnLabel?.value,
  };

  const [privacyValue, setPrivacyValue] = useState(
    !privacyInfo?.showRadioPrivacy
  );

  const promoValue = data?.promoValue?.value;
  const promoText = data?.promoValue?.content?.promoText?.value;

  // let dateOfBirthValid, emailValid, motorbikePlateValid;
  // dateOfBirthValid = emailValid = motorbikePlateValid = false; TODO

  const ref = useRef(null);

  const onSubmit = (data) => ref.current.submit();

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleChangemotorbikePlate = (e) => {
    setmotorbikePlate(e.target.value);
  };

  const handleChangeDateOfBirth = (e) => {
    setDateOfBirth(e.target.value);
  };

  const handleChangePrivacyRadio = (e) => {
    setPrivacyValue(e.target.value);
  };

  const [isConsentChecked, setIsConsentChecked] = useState('');
  const handleChangeCheckbox = (event) => {
    if (event.target.checked) {
      setIsConsentChecked('on');
    } else {
      setIsConsentChecked('');
    }
  };

  return (
    <div className='fast-quote-container'>
      <form
        className='fast-quote-form fast-quote-form--motorbike'
        onSubmit={handleSubmit(onSubmit)}
        method='POST'
        action={data?.btnLabel?.content?.btnLink?.value}
        ref={ref}
      >
        <fieldset className='fast-quote-group__wrapper'>
          <div className='fast-quote-group__wrapper--inputs'>
            <fieldset className='fast-quote-group fast-quote-group__motorbike'>
              <label
                className='fast-quote-form__label'
                htmlFor='motorbikePlate'
              >
                {data.plate.value}
              </label>
              <input
                className={classNames('fast-quote-form__input', {
                  'fast-quote-form__input--invalid':
                    errors['carPlate']?.message,
                })}
                id='motorbikePlate'
                type='text'
                name='motorbikePlate'
                maxLength={8}
                placeholder={data.plate.content.plateFocus.value}
                value={motorbikePlate}
                {...register('hero-quote-auto-license-plate', {
                  required: 'Campo obbligatorio',
                  onChange: handleChangemotorbikePlate,
                  maxLength: 8,
                  pattern: {
                    message: motorbikePlateErrorMessage,
                  },
                })}
              />
              <span className='fast-quote-form__helper-text'>
                {errors['carPlate'] &&
                  (errors['carPlate'] ? errors['carPlate'].message : '')}
              </span>
            </fieldset>

            <fieldset className='fast-quote-group fast-quote-group__birth'>
              <label
                className='fast-quote-form__label'
                htmlFor='dateOfBirthMotorbike'
              >
                {data?.birth?.value}
              </label>
              <input
                className={classNames('fast-quote-form__input', {
                  'fast-quote-form__input--invalid':
                    errors['dateOfBirth']?.message,
                })}
                type='datetime'
                name='dateOfBirthMotorbike'
                id='dateOfBirthMotorbike'
                placeholder={data?.birth?.content?.birthFocus?.value}
                value={dateOfBirth}
                {...register('hero-quote-auto-birthday', {
                  required: 'Campo obbligatorio',
                  onChange: handleChangeDateOfBirth,
                  pattern: {
                    value:
                      /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/i,
                    message: dateOfBirthErrrorMessage,
                  },
                })}
              />
              <span className='fast-quote-form__helper-text'>
                {errors['dateOfBirth'] &&
                  (errors['dateOfBirth'] ? errors['dateOfBirth'].message : '')}
              </span>
            </fieldset>

            <fieldset className='fast-quote-group fast-quote-group__email'>
              <label
                className='fast-quote-form__label'
                htmlFor='emailMotorbike'
              >
                {data.email.value}
              </label>
              <input
                className={classNames('fast-quote-form__input', {
                  'fast-quote-form__input--invalid': errors['email']?.message,
                })}
                type='text'
                id='emailMotorbike'
                placeholder={data.email.value}
                value={email}
                {...register('hero-quote-auto-email', {
                  required: 'Campo obbligatorio',
                  onChange: handleChangeEmail,
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: emailErrorMessage,
                  },
                })}
              />
              <span className='fast-quote-form__helper-text'>
                {errors['email'] &&
                  (errors['email'] ? errors['email'].message : '')}
              </span>
            </fieldset>
          </div>
          <div className='fast-quote-info'>
            <div className='fast-quote-info fast-quote-info-icon-wrapper'>
              <div className='fast-quote-info__icon'>
                <AlertCircle />
              </div>

              <div>
                {privacyInfo.introText}
                {privacyInfo.doc && (
                  <a
                    className='fast-quote-info__link'
                    href={privacyInfo.docURL}
                    title={privacyInfo.title}
                    target='blank'
                  >
                    {privacyInfo.title}
                  </a>
                )}
                {privacyInfo.showRadioPrivacy ? (
                  <fieldset>
                    {privacyInfo.btnValue && (
                      <legend>Cliccando su {privacyInfo.btnValue}</legend>
                    )}
                    <div className='fast-quote-info__radio'>
                      <input
                        type='radio'
                        name='privacyValue'
                        value='on'
                        id='privacyValueOK'
                        {...register('INFORMATIVA_PRIVACY', {
                          required: 'Campo obbligatorio',
                          onChange: handleChangePrivacyRadio,
                        })}
                      />
                      <label htmlFor='privacyValueOK'>
                        Sono d&apos;accordo
                      </label>

                      <input
                        type='radio'
                        name='privacyValue'
                        value='off'
                        id='privacyValueKO'
                        {...register('INFORMATIVA_PRIVACY', {
                          required: 'Campo obbligatorio',
                          onChange: handleChangePrivacyRadio,
                        })}
                      />
                      <label htmlFor='privacyValueKO'>
                        Non sono d&apos;accordo
                      </label>
                    </div>
                  </fieldset>
                ) : (
                  <input
                    type='hidden'
                    name='privacyValue'
                    value='on'
                    id='privacyValue'
                    {...register('INFORMATIVA_PRIVACY', {
                      required: false,
                    })}
                  />
                )}

                {privacyInfo.outroText}
              </div>
            </div>
            {commercialConsentInfo.introText && (
              <div className='fast-quote-info fast-quote-info-icon-wrapper'>
                <div>
                  <fieldset>
                    <div className='fast-quote-info__radio'>
                      <input
                        type='checkbox'
                        name='commercialConsentValue'
                        value={isConsentChecked}
                        onClick={handleChangeCheckbox}
                        id='commercialConsentValue'
                        {...register('CONSENSO_COMMERCIALE', {
                          required: false,
                        })}
                      />
                      <label htmlFor='commercialConsentValue'>
                        {commercialConsentInfo.introText}
                      </label>
                    </div>
                  </fieldset>
                  {commercialConsentInfo.outroText}
                </div>
              </div>
            )}
          </div>
        </fieldset>
        <fieldset className='fast-quote-group fast-quote-group__submit'>
          <label
            className='fast-quote-form__label fast-quote-form__label--submit'
            htmlFor='submitMotorbike'
          >
            {data?.btnLabel?.value}
          </label>
          <input
            className='fast-quote-form__input'
            type='submit'
            id='submitMotorbike'
            value={data?.btnLabel?.value}
            {...register('Submit', {
              // disabled: !(!!dateOfBirth && !!motorbikePlate && !!privacyValue),
              disabled: !(isValid && privacyValue),
            })}
          />
        </fieldset>
      </form>

      {promoValue && (
        <div className='fast-quote-container__promo-box'>
          <span className='fast-quote-container__promo-box-discount'>
            {promoValue}
          </span>
          <span className='fast-quote-container__promo-box-description'>
            {promoText}
          </span>
        </div>
      )}
    </div>
  );
}

export default withPreview(FastQuoteMotorbike);
