import React from 'react';

function FastQuoteTabBody(props) {
  return (
    <div className='tabcontent-list__item-body'>
      {/* {props.type} */}
      {props.formComponent}
    </div>
  );
}
export default FastQuoteTabBody;
