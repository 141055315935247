import React from 'react';
import Icon from '../../atoms/icon';
import useWithMobileResizeListener from '../../utils/hooks/useWithMobileResizeListener';

function FastQuoteTabLabel(props) {
  const isMobile = useWithMobileResizeListener(768);

  const renderLabel = () => {
    const span = (
      <span className='fast-quote__icon-name'>{props.labelString}</span>
    );
    if (isMobile) {
      return props.isMobileLabelVisible ? span : null;
    }
    return span;
  };
  return (
    <>
      <span
        className={`fast-quote__icon fast-quote__icon--${props.type} ${props.type}-selected`}
      >
        <Icon icon={props.labelIconKey} thickness={props.labelIconThickness} />
      </span>
      {renderLabel()}
    </>
  );
}
export default FastQuoteTabLabel;
