import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import AlertCircle from '../../atoms/static-icons/alert-circle';
import withPreview from '../../utils/with-preview';
import classNames from 'classnames';
import { ButtonWithLink as Button } from '../button/Button';
import Select from 'react-select';

function FastQuotePuppy({ data }) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'all',
  });

  const privacyInfo = {
    introText: data?.privacy?.content?.privacyIntroText?.value,
    doc: data?.privacy?.content?.privacyDoc,
    docURL: data?.privacy?.content?.privacyDoc?.value?.contentUrl,
    title: data?.privacy?.value,
    outroText: data?.privacy?.content?.privacyOutroText?.value,
    showRadioPrivacy: data?.privacy?.content?.showRadioPrivacy?.value,
    btnValue: data?.btnLabel?.value,
  };
  const commercialConsentInfo = {
    introText:
      data?.commercialConsent?.content?.commercialConsentIntroText?.value,
    doc: data?.commercialConsent?.content?.commercialConsentDoc,
    docURL:
      data?.commercialConsent?.content?.commercialConsentDoc?.value?.contentUrl,
    title: data?.commercialConsent?.value,
    outroText:
      data?.commercialConsent?.content?.commercialConsentOutroText?.value,
    btnValue: data?.btnLabel?.value,
  };
  const promoValue = data?.promoValue?.value;
  const promoText = data?.promoValue?.content?.promoText?.value;
  const emailErrorMessage = 'Inserisci un formato di email valido.';
  const petTypeOptions = data?.petTypePlaceholder?.content?.petType?.map(
    (item, i) => {
      return {
        label: item?.value,
        value: (i + 1).toString(),
      };
    }
  );
  const ageOptions = data?.agePlaceholder?.content?.age?.map((item, i) => {
    return {
      label: item?.value,
      value: (i + 1).toString(),
    };
  });

  const onSubmit = async (values) => {
    try {
      const res = await fetch(
        `${data?.btnLabel?.content?.btnLink?.value}/api/common/datatrasporterwritedata`,
        {
          method: 'POST',
          body: JSON.stringify({
            petType: values?.petType,
            age: values?.age,
            email: values?.email,
            privacy: values?.privacy,
            consensoCommerciale: !!values?.consensoCommerciale ? 'on' : 'off',
          }),
        }
      );
      const { guid } = await res.json();
      if (guid) {
        window.location.href = `${data?.btnLabel?.content?.btnLink?.value}/preventivatori/active-protezione-cucciolo/step-1?guid=${guid}`;
      }
    } catch (err) {
      console.error(`Error submitting data`, err);
    }
  };

  return (
    <div className='fast-quote-container'>
      <form
        className='fast-quote-form fast-quote-form--puppy'
        onSubmit={handleSubmit(onSubmit)}
      >
        <fieldset className='fast-quote-group__wrapper'>
          <div className='fast-quote-group__wrapper--inputs'>
            <fieldset className='fast-quote-group fast-quote-group__petType'>
              <label className='fast-quote-form__label' htmlFor='petTypePuppy'>
                {data?.petTypePlaceholder?.value}
              </label>
              <Controller
                control={control}
                name='petType'
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    value={petTypeOptions.find((c) => c.value === value)}
                    onChange={(val) => onChange(val.value)}
                    options={petTypeOptions}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    classNamePrefix='select'
                    className='fast-quote-form__select fast-quote-form__select--jobtype'
                    id='petTypePuppy'
                    isSearchable={false}
                    placeholder={
                      data?.petTypePlaceholder?.content?.petTypeFocusPlaceholder
                        ?.value
                    }
                    // menuPortalTarget={document.body}
                    // styles={{ menuPortal: (base) => ({ ...base, zIndex: 10 }) }}
                  />
                )}
              />
            </fieldset>
            <fieldset className='fast-quote-group fast-quote-group__age'>
              <label className='fast-quote-form__label' htmlFor='agePuppy'>
                {data?.agePlaceholder?.value}
              </label>
              <Controller
                control={control}
                name='age'
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    value={ageOptions.find((c) => c.value === value)}
                    onChange={(val) => onChange(val.value)}
                    options={ageOptions}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    classNamePrefix='select'
                    className='fast-quote-form__select fast-quote-form__select--jobtype'
                    id='agePuppy'
                    isSearchable={false}
                    placeholder={
                      data?.agePlaceholder?.content?.ageFocusPlaceholder?.value
                    }
                    // menuPortalTarget={document.body}
                    // styles={{ menuPortal: (base) => ({ ...base, zIndex: 10 }) }}
                  />
                )}
              />
            </fieldset>
            <fieldset className='fast-quote-group fast-quote-group__email'>
              <label className='fast-quote-form__label' htmlFor='emailPuppy'>
                {data.emailPlaceholder?.value}
              </label>
              <input
                className={classNames('fast-quote-form__input', {
                  'fast-quote-form__input--invalid': errors['Email']?.message,
                })}
                type='text'
                id='emailPuppy'
                placeholder={data.emailPlaceholder?.value}
                {...register('email', {
                  required: 'Campo obbligatorio',
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: emailErrorMessage,
                  },
                })}
              />
              <span className='fast-quote-form__helper-text'>
                {errors['Email'] &&
                  (errors['Email'] ? errors['Email'].message : '')}
              </span>
            </fieldset>
          </div>
          {data?.infoMessage?.value && (
            <div
              className='fast-quote-info__message'
              dangerouslySetInnerHTML={{ __html: data.infoMessage.value }}
            ></div>
          )}
          <div className='fast-quote-info'>
            <div className='fast-quote-info fast-quote-info-icon-wrapper'>
              <div className='fast-quote-info__icon'>
                <AlertCircle />
              </div>
              <div>
                {privacyInfo.introText}
                {privacyInfo.doc && (
                  <a
                    className='fast-quote-info__link'
                    href={privacyInfo.docURL}
                    title={privacyInfo.title}
                    target='blank'
                  >
                    {privacyInfo.title}
                  </a>
                )}
                {privacyInfo.showRadioPrivacy ? (
                  <fieldset>
                    {privacyInfo.btnValue && (
                      <legend>Cliccando su {privacyInfo.btnValue}</legend>
                    )}
                    <div className='fast-quote-info__radio'>
                      <input
                        type='radio'
                        value='on'
                        id='privacyValueOK'
                        {...register('privacy', {
                          required: 'Campo obbligatorio',
                        })}
                      />
                      <label htmlFor='privacyValueOK'>
                        Sono d&apos;accordo
                      </label>
                      <input
                        type='radio'
                        value='off'
                        id='privacyValueKO'
                        {...register('privacy', {
                          required: 'Campo obbligatorio',
                        })}
                      />
                      <label htmlFor='privacyValueKO'>
                        Non sono d&apos;accordo
                      </label>
                    </div>
                  </fieldset>
                ) : (
                  <input
                    type='hidden'
                    value='on'
                    id='privacyValue'
                    {...register('privacy', {
                      required: false,
                    })}
                  />
                )}
                {privacyInfo.outroText}
              </div>
            </div>

            {commercialConsentInfo.introText && (
              <div className='fast-quote-info fast-quote-info-icon-wrapper'>
                <div>
                  <fieldset>
                    <div className='fast-quote-info__radio'>
                      <input
                        type='checkbox'
                        id='commercialConsentValue'
                        {...register('consensoCommerciale', {
                          required: false,
                        })}
                      />
                      <label htmlFor='commercialConsentValue'>
                        {commercialConsentInfo.introText}
                      </label>
                    </div>
                  </fieldset>
                  {commercialConsentInfo.outroText}
                </div>
              </div>
            )}
          </div>
        </fieldset>
        <fieldset className='fast-quote-group fast-quote-group__submit'>
          <label
            className='fast-quote-form__label fast-quote-form__label--submit'
            htmlFor='submitPuppy'
          >
            {data?.btnLabel?.value}
          </label>
          {data?.btnLabel?.content?.btnLink?.value && (
            <input
              className='fast-quote-form__input'
              type='submit'
              id='submitPuppy'
              value={data?.btnLabel?.value}
              {...register('Submit', {
                disabled: !isValid,
              })}
            />
          )}
          {data?.offerLabel?.value &&
            data?.offerLabel?.content?.offerLink?.value && (
              <Button
                type='secondary'
                href={data.offerLabel.content.offerLink.value}
                title={data.offerLabel.value}
                text={data.offerLabel.value}
                className='fast-quote-form__offer'
              ></Button>
            )}
        </fieldset>
      </form>
      {promoValue && (
        <div className='fast-quote-container__promo-box'>
          <span className='fast-quote-container__promo-box-discount'>
            {promoValue}
          </span>
          <span className='fast-quote-container__promo-box-description'>
            {promoText}
          </span>
        </div>
      )}
    </div>
  );
}

export default withPreview(FastQuotePuppy);
