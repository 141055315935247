import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import AlertCircle from '../../atoms/static-icons/alert-circle';
import withPreview from '../../utils/with-preview';
import classNames from 'classnames';

function FastQuoteCar({ data }) {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'all',
  });

  const [carPlate, setCarPlate] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [email, setEmail] = useState('');
  const carPlateErrorMessage = 'Inserisci una targa lunga al massimo 8 cifre';
  const dateOfBirthErrrorMessage = 'Inserisci una data in formato GG/MM/AAAA';
  const emailErrorMessage = 'Inserisci un formato di email valido.';

  const privacyInfo = {
    introText: data?.privacy?.content?.privacyIntroText?.value,
    doc: data?.privacy?.content?.privacyDoc,
    docURL: data?.privacy?.content?.privacyDoc?.value?.contentUrl,
    title: data?.privacy?.value,
    outroText: data?.privacy?.content?.privacyOutroText?.value,
    showRadioPrivacy: data?.privacy?.content?.showRadioPrivacy?.value,
    btnValue: data?.btnLabel?.value,
  };

  const commercialConsentInfo = {
    introText:
      data?.commercialConsent?.content?.commercialConsentIntroText?.value,
    doc: data?.commercialConsent?.content?.commercialConsentDoc,
    docURL:
      data?.commercialConsent?.content?.commercialConsentDoc?.value?.contentUrl,
    title: data?.commercialConsent?.value,
    outroText:
      data?.commercialConsent?.content?.commercialConsentOutroText?.value,
    // showRadioCommercialConsent:
    //   data?.commercialConsent?.content?.showRadioCommercialConsent?.value,
    btnValue: data?.btnLabel?.value,
  };

  const [privacyValue, setPrivacyValue] = useState(
    !privacyInfo?.showRadioPrivacy
  );

  const promoValue = data?.promoValue?.value;
  const promoText = data?.promoValue?.content?.promoText?.value;

  const ref = useRef(null);

  const onSubmit = (data) => ref.current.submit();

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleChangeCarPlate = (e) => {
    setCarPlate(e.target.value);
  };

  const handleChangeDateOfBirth = (e) => {
    setDateOfBirth(e.target.value);
  };

  const handleChangePrivacyRadio = (e) => {
    setPrivacyValue(e.target.value);
  };

  const [isConsentChecked, setIsConsentChecked] = useState('');
  const handleChangeCheckbox = (event) => {
    if (event.target.checked) {
      setIsConsentChecked('on');
    } else {
      setIsConsentChecked('');
    }
  };

  return (
    <div className='fast-quote-container'>
      <form
        className='fast-quote-form fast-quote-form--car'
        onSubmit={handleSubmit(onSubmit)}
        method='POST'
        action={data?.btnLabel?.content?.btnLink?.value}
        ref={ref}
      >
        <fieldset className='fast-quote-group__wrapper'>
          <div className='fast-quote-group__wrapper--inputs'>
            <fieldset className='fast-quote-group fast-quote-group__car'>
              <label className='fast-quote-form__label' htmlFor='carPlate'>
                {data.plate.value}
              </label>
              <input
                className={classNames('fast-quote-form__input', {
                  'fast-quote-form__input--invalid':
                    errors['Targa Veicolo']?.message,
                })}
                id='carPlate'
                type='text'
                name='carPlate'
                maxLength={8}
                placeholder={data.plate.content.plateFocus.value}
                value={carPlate}
                {...register('hero-quote-auto-license-plate', {
                  required: 'Campo obbligatorio',
                  onChange: handleChangeCarPlate,
                  maxLength: 8,
                  pattern: {
                    message: carPlateErrorMessage,
                  },
                })}
              />
              <span className='fast-quote-form__helper-text'>
                {errors['Targa Veicolo'] &&
                  (errors['Targa Veicolo']
                    ? errors['Targa Veicolo'].message
                    : '')}
              </span>
            </fieldset>

            <fieldset className='fast-quote-group fast-quote-group__birth'>
              <label className='fast-quote-form__label' htmlFor='dateOfBirth'>
                {data?.birth?.value}
              </label>
              <input
                className={classNames('fast-quote-form__input', {
                  'fast-quote-form__input--invalid':
                    errors['Data di nascita']?.message,
                })}
                type='datetime'
                name='dateOfBirth'
                id='dateOfBirth'
                placeholder={data?.birth?.content?.birthFocus?.value}
                value={dateOfBirth}
                {...register('hero-quote-auto-birthday', {
                  required: 'Campo obbligatorio',
                  onChange: handleChangeDateOfBirth,
                  pattern: {
                    value:
                      /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/i,
                    message: dateOfBirthErrrorMessage,
                  },
                })}
              />
              <span className='fast-quote-form__helper-text'>
                {errors['Data di nascita'] &&
                  (errors['Data di nascita']
                    ? errors['Data di nascita'].message
                    : '')}
              </span>
            </fieldset>

            <fieldset className='fast-quote-group fast-quote-group__email'>
              <label className='fast-quote-form__label' htmlFor='email'>
                {data.email.value}
              </label>
              <input
                className={classNames('fast-quote-form__input', {
                  'fast-quote-form__input--invalid': errors['Email']?.message,
                })}
                type='text'
                id='email'
                placeholder={data.email.value}
                value={email}
                {...register('hero-quote-auto-email', {
                  required: 'Campo obbligatorio',
                  onChange: handleChangeEmail,
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: emailErrorMessage,
                  },
                })}
              />
              <span className='fast-quote-form__helper-text'>
                {errors['Email'] &&
                  (errors['Email'] ? errors['Email'].message : '')}
              </span>
            </fieldset>
          </div>
          <div className='fast-quote-info'>
            <div className='fast-quote-info fast-quote-info-icon-wrapper'>
              <div className='fast-quote-info__icon'>
                <AlertCircle />
              </div>

              <div>
                {privacyInfo.introText}
                {privacyInfo.doc && (
                  <a
                    className='fast-quote-info__link'
                    href={privacyInfo.docURL}
                    title={privacyInfo.title}
                    target='blank'
                  >
                    {privacyInfo.title}
                  </a>
                )}
                {privacyInfo.showRadioPrivacy ? (
                  <fieldset>
                    {privacyInfo.btnValue && (
                      <legend>Cliccando su {privacyInfo.btnValue}</legend>
                    )}
                    <div className='fast-quote-info__radio'>
                      <input
                        type='radio'
                        name='privacyValue'
                        value='on'
                        id='privacyValueOK'
                        {...register('INFORMATIVA_PRIVACY', {
                          required: 'Campo obbligatorio',
                          onChange: handleChangePrivacyRadio,
                        })}
                      />
                      <label htmlFor='privacyValueOK'>
                        Sono d&apos;accordo
                      </label>

                      <input
                        type='radio'
                        name='privacyValue'
                        value='off'
                        id='privacyValueKO'
                        {...register('INFORMATIVA_PRIVACY', {
                          required: 'Campo obbligatorio',
                          onChange: handleChangePrivacyRadio,
                        })}
                      />
                      <label htmlFor='privacyValueKO'>
                        Non sono d&apos;accordo
                      </label>
                    </div>
                  </fieldset>
                ) : (
                  <input
                    type='hidden'
                    name='privacyValue'
                    value='on'
                    id='privacyValue'
                    {...register('INFORMATIVA_PRIVACY', {
                      required: false,
                    })}
                  />
                )}

                {privacyInfo.outroText}
              </div>
            </div>

            {commercialConsentInfo.introText && (
              <div className='fast-quote-info fast-quote-info-icon-wrapper'>
                <div>
                  <fieldset>
                    <div className='fast-quote-info__radio'>
                      <input
                        type='checkbox'
                        name='commercialConsentValue'
                        value={isConsentChecked}
                        onClick={handleChangeCheckbox}
                        id='commercialConsentValue'
                        {...register('CONSENSO_COMMERCIALE', {
                          required: false,
                        })}
                      />
                      <label htmlFor='commercialConsentValue'>
                        {commercialConsentInfo.introText}
                      </label>
                    </div>
                  </fieldset>
                  {commercialConsentInfo.outroText}
                </div>
              </div>
            )}
          </div>
        </fieldset>
        <fieldset className='fast-quote-group fast-quote-group__submit'>
          <label
            className='fast-quote-form__label fast-quote-form__label--submit'
            htmlFor='submitCar'
          >
            {data?.btnLabel?.value}
          </label>
          {data.btnLabel.content.btnLink.value && (
            <input
              className='fast-quote-form__input'
              type='submit'
              id='submitCar'
              value={data?.btnLabel?.value}
              {...register('Submit', {
                //disabled: !(!!dateOfBirth && !!carPlate && !!privacyValue),
                disabled: !(isValid && privacyValue),
              })}
            />
          )}
        </fieldset>
      </form>
      {promoValue && (
        <div className='fast-quote-container__promo-box'>
          <span className='fast-quote-container__promo-box-discount'>
            {promoValue}
          </span>
          <span className='fast-quote-container__promo-box-description'>
            {promoText}
          </span>
        </div>
      )}
    </div>
  );
}

export default withPreview(FastQuoteCar);
