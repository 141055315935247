import React from 'react';
import { useForm } from 'react-hook-form';
import withPreview from '../../utils/with-preview';
import AlertCircle from '../../atoms/static-icons/alert-circle';
import classNames from 'classnames';
import { ButtonWithLink as Button } from '../button/Button';

function FastQuoteHome({ data }) {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'all',
  });

  const privacyInfo = {
    introText: data?.privacy?.content?.privacyIntroText?.value,
    doc: data?.privacy?.content?.privacyDoc,
    docURL: data?.privacy?.content?.privacyDoc?.value?.contentUrl,
    title: data?.privacy?.value,
    outroText: data?.privacy?.content?.privacyOutroText?.value,
    showRadioPrivacy: data?.privacy?.content?.showRadioPrivacy?.value,
    btnValue: data?.btnLabel?.value,
  };
  const commercialConsentInfo = {
    introText:
      data?.commercialConsent?.content?.commercialConsentIntroText?.value,
    doc: data?.commercialConsent?.content?.commercialConsentDoc,
    docURL:
      data?.commercialConsent?.content?.commercialConsentDoc?.value?.contentUrl,
    title: data?.commercialConsent?.value,
    outroText:
      data?.commercialConsent?.content?.commercialConsentOutroText?.value,
    btnValue: data?.btnLabel?.value,
  };
  const promoValue = data?.promoValue?.value;
  const promoText = data?.promoValue?.content?.promoText?.value;
  const mqErrorMessage = 'Inserire un valore numerico';
  const emailErrorMessage = 'Inserisci un formato di email valido.';

  const onSubmit = async (values) => {
    try {
      const res = await fetch(
        `${data?.btnLabel?.content?.btnLink?.value}/api/common/datatrasporterwritedata`,
        {
          method: 'POST',
          body: JSON.stringify({
            mq: values?.mq,
            email: values?.email,
            privacy: values?.privacy,
            consensoCommerciale: !!values?.consensoCommerciale ? 'on' : 'off',
          }),
        }
      );
      const { guid } = await res.json();
      if (guid) {
        window.location.href = `${data?.btnLabel?.content?.btnLink?.value}/preventivatori/active-protezione-casa/step-1?guid=${guid}`;
      }
    } catch (err) {
      console.error(`Error submitting data`, err);
    }
  };

  return (
    <div className='fast-quote-container'>
      <form
        className='fast-quote-form fast-quote-form--home'
        onSubmit={handleSubmit(onSubmit)}
      >
        <fieldset className='fast-quote-group__wrapper'>
          <div className='fast-quote-group__wrapper--inputs'>
            <fieldset className='fast-quote-group fast-quote-group__home fast-quote-group__mq'>
              <label className='fast-quote-form__label' htmlFor='mq'>
                {data?.homeMq?.value}
              </label>
              <input
                className={classNames('fast-quote-form__input', {
                  'fast-quote-form__input--invalid': errors['mq']?.message,
                })}
                id='mq'
                type='text'
                placeholder={data?.homeMq?.value}
                {...register('mq', {
                  required: 'Campo obbligatorio',
                  pattern: {
                    value: /[0-9]/,
                    message: mqErrorMessage,
                  },
                })}
              />
              <span className='fast-quote-form__helper-text'>
                {errors['mq'] && (errors['mq'] ? errors['mq'].message : '')}
              </span>
            </fieldset>

            <fieldset className='fast-quote-group fast-quote-group__email'>
              <label className='fast-quote-form__label' htmlFor='emailHome'>
                {data?.emailPlaceholder?.value}
              </label>
              <input
                className={classNames('fast-quote-form__input', {
                  'fast-quote-form__input--invalid': errors['Email']?.message,
                })}
                type='text'
                id='emailHome'
                placeholder={data?.emailPlaceholder?.value}
                {...register('email', {
                  required: 'Campo obbligatorio',
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: emailErrorMessage,
                  },
                })}
              />
              <span className='fast-quote-form__helper-text'>
                {errors['Email'] &&
                  (errors['Email'] ? errors['Email'].message : '')}
              </span>
            </fieldset>
          </div>
          <div className='fast-quote-info'>
            <div className='fast-quote-info fast-quote-info-icon-wrapper'>
              <div className='fast-quote-info__icon'>
                <AlertCircle />
              </div>
              <div>
                {privacyInfo.introText}
                {privacyInfo.doc && (
                  <a
                    className='fast-quote-info__link'
                    href={privacyInfo.docURL}
                    title={privacyInfo.title}
                    target='blank'
                  >
                    {privacyInfo.title}
                  </a>
                )}
                {privacyInfo.showRadioPrivacy ? (
                  <fieldset>
                    {privacyInfo.btnValue && (
                      <legend>Cliccando su {privacyInfo.btnValue}</legend>
                    )}
                    <div className='fast-quote-info__radio'>
                      <input
                        type='radio'
                        value='on'
                        id='privacyValueOK'
                        {...register('privacy', {
                          required: 'Campo obbligatorio',
                        })}
                      />
                      <label htmlFor='privacyValueOK'>
                        Sono d&apos;accordo
                      </label>
                      <input
                        type='radio'
                        value='off'
                        id='privacyValueKO'
                        {...register('privacy', {
                          required: 'Campo obbligatorio',
                        })}
                      />
                      <label htmlFor='privacyValueKO'>
                        Non sono d&apos;accordo
                      </label>
                    </div>
                  </fieldset>
                ) : (
                  <input
                    type='hidden'
                    value='on'
                    id='privacyValue'
                    {...register('privacy', {
                      required: false,
                    })}
                  />
                )}
                {privacyInfo.outroText}
              </div>
            </div>

            {commercialConsentInfo.introText && (
              <div className='fast-quote-info fast-quote-info-icon-wrapper'>
                <div>
                  <fieldset>
                    <div className='fast-quote-info__radio'>
                      <input
                        type='checkbox'
                        id='commercialConsentValue'
                        {...register('consensoCommerciale', {
                          required: false,
                        })}
                      />
                      <label htmlFor='commercialConsentValue'>
                        {commercialConsentInfo.introText}
                      </label>
                    </div>
                  </fieldset>
                  {commercialConsentInfo.outroText}
                </div>
              </div>
            )}
          </div>
        </fieldset>
        <fieldset className='fast-quote-group fast-quote-group__submit'>
          <label
            className='fast-quote-form__label fast-quote-form__label--submit'
            htmlFor='submitHome'
          >
            {data?.btnLabel?.value}
          </label>
          {data?.btnLabel?.content?.btnLink?.value && (
            <input
              className='fast-quote-form__input'
              type='submit'
              id='submitHome'
              value={data?.btnLabel?.value}
              {...register('Submit', {
                disabled: !isValid,
              })}
            />
          )}
          {data?.offerLabel?.value &&
            data?.offerLabel?.content?.offerLink?.value && (
              <Button
                type='secondary'
                href={data.offerLabel.content.offerLink.value}
                title={data.offerLabel.value}
                text={data.offerLabel.value}
                className='fast-quote-form__offer'
              ></Button>
            )}
        </fieldset>
      </form>
      {promoValue && (
        <div className='fast-quote-container__promo-box'>
          <span className='fast-quote-container__promo-box-discount'>
            {promoValue}
          </span>
          <span className='fast-quote-container__promo-box-description'>
            {promoText}
          </span>
        </div>
      )}
    </div>
  );
}

export default withPreview(FastQuoteHome);
